import { createAction, handleActions } from 'redux-actions'

const initialState = {
    list: {},
    settings: {},
    freecode: {}
}

const GET_LIST = 'payment/GET_LIST'
const GET_LIST_SUCCESS = 'payment/GET_LIST_SUCCESS'
const GET_FREE = 'payment/GET_FREE'
const GET_FREE_CODE = 'paymetn/GET_FREE_CODE'
const POST_FREE = 'payment/POST_FREE'
const POST_FREE_CODE = 'payment/POST_FREE_CODE'
const APPLY_FREE = 'payment/APPLY_FREE'
const GENERATE_FREE_CODE = 'payment/GENERATE_FREE_CODE'

const DEFAULT_ASSIGN = 'payment/DEFAULT_ASSIGN'

export const getList = createAction(GET_LIST)
export const getListSuccess = createAction(GET_LIST_SUCCESS)
export const getFree = createAction(GET_FREE)
export const getFreeCode = createAction(GET_FREE_CODE)
export const postFree = createAction(POST_FREE)
export const postFreeCode = createAction(POST_FREE_CODE)

export const applyFree = createAction(APPLY_FREE)
export const generateFreeCode = createAction(GENERATE_FREE_CODE)

export const defaultAssign = createAction(DEFAULT_ASSIGN)

export const sagaTypes = {
  GET_LIST,
  GET_FREE,
  GET_FREE_CODE,
  POST_FREE,
  POST_FREE_CODE,
  APPLY_FREE,
  GENERATE_FREE_CODE
}

/**
 * type
 * payload
 */
export default handleActions({
  [GET_LIST_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  [DEFAULT_ASSIGN]: (state, action) => ({
    ...state,
    ...action.payload
  })
}, initialState)
