import { request } from '../modules/request'
import { BASE_URL } from '../constants'

export const clicks = {
  getList({ query }) {
    const { page, size, select = '', word = '' } = query
    const {system= false,
      click= false,
      chat= false,
      after= false,
      plan= false,
      made= false,
      complete= false,
      reject= false,
      pass= false} = query


    return request(
      `${BASE_URL}/clicks?page=${page}&size=${size}&select=${select}&word=${word}&system=${system}&click=${click}&chat=${chat}&after=${after}&plan=${plan}&made=${made}&complete=${complete}&reject=${reject}&pass=${pass}`,
      'get',
      {}
    )
  },
  get({ id }) {
    return request(
      `${BASE_URL}/clicks/${id}`,
      'get',
      {}
    )
  },
  getReportList({ query }) {
    const { page, size, select = '', word = '' } = query


    return request(
      `${BASE_URL}/reports?page=${page}&size=${size}&select=${select}&word=${word}`,
      'get',
      {}
    )
  },
  getReportClickList({ query }) {
    const { page, size, select = '', word = '' } = query


    return request(
      `${BASE_URL}/reports/clicks?page=${page}&size=${size}&select=${select}&word=${word}`,
      'get',
      {}
    )
  },
  getReportUserList({ query }) {
    const { page, size, select = '', word = '' } = query


    return request(
      `${BASE_URL}/reports/users?page=${page}&size=${size}&select=${select}&word=${word}`,
      'get',
      {}
    )
  },
  getReportReplyList({ query }) {
    const { page, size, select = '', word = '' } = query


    return request(
      `${BASE_URL}/reports/replies?page=${page}&size=${size}&select=${select}&word=${word}`,
      'get',
      {}
    )
  },
}
