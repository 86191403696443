import { request } from '../modules/request'
import { BASE_URL } from '../constants'

export const dashboard = {
  info: {
    get() {
      return request(
        `${BASE_URL}/dashboard/info`,
        'get',
        {}
      )
    }
  },
  job: {
    get() {
      return request(
        `${BASE_URL}/dashboard/job`,
        'get',
        {}
      )
    }
  }
}
