import { request } from "../modules/request";
import { BASE_URL } from "../constants";

export const contents = {
  getCardResults({ query }) {
    const { page, size } = query;
    return request(
      `${BASE_URL}/contents/card-results?page=${page}&size=${size}`,
      "get",
      {}
    );
  },
  getCardResult({ id }) {
    return request(`${BASE_URL}/contents/card-results/${id}`, "get", {});
  },
  putCardResult({ id, cardSubject, cardResult }) {
    return request(`${BASE_URL}/contents/card-results/${id}`, "put", {
      cardSubject,
      cardResult,
    });
  },
};
