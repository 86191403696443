import { createAction, handleActions } from 'redux-actions'

const initialState = {
  list: {},
  eduList: [],
  jobList: [],
  workList: [],
  detail: {},
  test: '',
  noClickList: []
}

const GET_LIST = 'users/GET_LIST'
const GET_LIST_SUCCESS = 'users/GET_LIST_SUCCESS'
const GET_APPROVE_LIST = 'users/GET_APPROVE_LIST'
const GET_APPROVE_LIST_SUCCESS = 'users/GET_APPROVE_LIST_SUCCESS'
const GET_CAREER_LIST ='users/GET_CAREER_LIST'
const POST_CAREER_REFERENCE = 'users/POST_CAREER_REFERENCE'
const GET_PUSH = 'users/GET_PUSH'
const GET_PUSH_DETAIL = 'users/GET_PUSH_DETAIL'
const POST_PUSH = 'users/POST_PUSH'
const GET_CAREER_LIST_SUCCESS ='users/GET_CAREER_LIST_SUCCESS'
const GET_USER = 'users/GET_USER'
const GET_NO_CLICK_USER = 'users/GET_NO_CLICK_USER'
const GET_NO_CLICK_USER_SUCCESS = 'users/GET_NO_CLICK_USER_SUCCESS'
const POST_APPROVE = 'users/POST_APPROVE'
const DELETE_USER = 'users/DELETE_USER'
const PUT_USER = 'users/PUT_USER'
const DEFAULT_ASSIGN = 'users/DEFAULT_ASSIGN'
export const getApproveList = createAction(GET_APPROVE_LIST)
export const getApproveListSuccess = createAction(GET_APPROVE_LIST_SUCCESS)
export const getList = createAction(GET_LIST)
export const getListSuccess = createAction(GET_LIST_SUCCESS)
export const getUser = createAction(GET_USER)
export const getNoClickUser = createAction(GET_NO_CLICK_USER)

export const getNoClickUserSuccess = createAction(GET_NO_CLICK_USER_SUCCESS)
export const putUser = createAction(PUT_USER)

export const getCareerList = createAction(GET_CAREER_LIST)
export const postApprove = createAction(POST_APPROVE)
export const postCareerReference = createAction(POST_CAREER_REFERENCE)
export const getPush = createAction(GET_PUSH)

export const getPushDetail = createAction(GET_PUSH_DETAIL)
export const postPush = createAction(POST_PUSH)
export const getCareerListSuccess = createAction(GET_CAREER_LIST_SUCCESS)

export const deleteUser = createAction(DELETE_USER)
export const defaultAssign = createAction(DEFAULT_ASSIGN)

export const sagaTypes = {
  GET_LIST,
  GET_APPROVE_LIST,
  GET_USER,
  GET_NO_CLICK_USER,
  DELETE_USER,
  POST_CAREER_REFERENCE,
  GET_PUSH,
  GET_PUSH_DETAIL,
  POST_PUSH,
  POST_APPROVE,
  PUT_USER,
  GET_CAREER_LIST,
  GET_CAREER_LIST_SUCCESS
}

/**
 * type
 * payload
 */
export default handleActions({
  [GET_APPROVE_LIST_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  [GET_CAREER_LIST_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  [GET_LIST_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload
  }),

  [GET_NO_CLICK_USER_SUCCESS]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  [DEFAULT_ASSIGN]: (state, action) => ({
    ...state,
    ...action.payload
  }),
}, initialState)
