import { call, takeEvery, put, select } from 'redux-saga/effects';
import { api } from '../services'

import * as actions from '../reducers/users'
const { sagaTypes } = actions

export function* getApproveListFlow({ payload }) {
  console.log('@@@@ payload: ', payload)
  const { page, size, state } = payload

  let res = null
  try {
    res = yield call(
      api.user.getApproveList,
      {
        query: {
          page,
          size,
          state
        }
      }
    )

    yield put(
      actions.getApproveListSuccess({
        list: res.data
      })
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}
export function* getCareerReferenceListFlow({ payload }) {
  let res = null;
  try {
    const {page, subType, type, value, value2} = payload;
    if (type === 'EDUCATION') {

      res = yield call(api.user.getCareerReferenceList, {
        page,
        type: 'EDUCATION',
        subType: '',
        value,
        value2
      });
      console.log("@@@@ getCareerReferenceListFlow res: ", res.data);

      yield put(
        actions.defaultAssign({
          eduList: res.data,
        })
      );
    }
    if (type === 'JOB') {
      res = yield call(api.user.getCareerReferenceList, {
        page,
        type: 'JOB',
        value
      });
      yield put(
        actions.defaultAssign({
          jobList: res.data,
        })
      );
    }

    if (type === 'WORKSPACE') {
      res = yield call(api.user.getCareerReferenceList, {
        page,
        subType,
        type: 'WORKSPACE',
        value,
        value2
      });
      yield put(
        actions.defaultAssign({
          workList: res.data,
        })
      );
    }
  } catch (error) {
    console.error("ERR: ", error);
  }

}
export function* getListFlow({ payload }) {
  console.log('@@@@ payload: ', payload)
  const { page, size, select, word } = payload

  let res = null
  try {
    res = yield call(
      api.user.getList,
      {
        query: {
          page,
          size,
          select,
          word
        }
      }
    )

    yield put(
      actions.getListSuccess({
        list: res.data
      })
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}
export function* getNoClickListFlow({ payload }) {
  console.log('@@@@ payload: ', payload)
  const { page, size } = payload

  let res = null
  try {
    console.log("@@@@ start")
    res = yield call(
      api.user.getNoClick,
      {
        query: {
          page,
          size,
        }
      }
    )
    console.log("@@@@ res.data", res.data)

    yield put(
      actions.getNoClickUserSuccess({
        noClickList: res.data
      })
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}

export function* getUserFlow({ payload }) {
  const { id } = payload

  let res = null
  try {
    res = yield call(
      api.user.get,
      {
        id
      }
    )
    console.log('@@@@ res: ', res)

    yield put(
      actions.defaultAssign({
        detail: res.data
      })
    )

  } catch (e) {
    console.error('ERR: ', e)
  }
}



export function* deleteUserFlow({ payload }) {
  let res = null
  const { history, id } = payload
  try {
    console.log('@@@@ payload: ', payload)
    res = yield call(
      api.user.delete,
      {
        id,
      }
    )

    history.push('/users')
  } catch (e) {
    console.error('ERR: ', e)
  }
}

export function* putUserFlow({ payload }) {
  // 사가에서 같은지 안같은지 확인 후 put 태우기
  console.log('@@@@ putUserFlow enter: ', payload)
  const { history, user } = payload
  let res = null
  try {
    let data = Object.assign(user, {})

    console.log('@@@@ data: ', data)

    res = yield call(
      api.user.put,
      {
        id: user.id,
        data
      }
    )

    history.push('/users')
  } catch (e) {
    console.error('ERR: ', e)
  }
}
export function* postApprove({ payload }) {
  // 사가에서 같은지 안같은지 확인 후 put 태우기
  console.log('@@@@ postApprove enter: ', payload)
  const { type, score } = payload
  let res = null
  try {
    // let data = Object.assign(user, {})


    console.log('@@@@ data: ', payload)
    // alert('test' + window.location )
    res = yield call(
      api.user.postApprove,
      {
        ...payload
      }
    )

    window.location.reload()
    // history.push('/users')
  } catch (e) {
    console.error('ERR: ', e)
  }
}
export function* postPush({ payload }) {
  // 사가에서 같은지 안같은지 확인 후 put 태우기
  console.log('@@@@ postPush enter: ', payload)
  const { title, body } = payload
  let res = null
  try {
    // let data = Object.assign(user, {})


    console.log('@@@@ data: ', payload)
    if(body === ''){
      alert('body 입력해주세요.')
    }else {
      res = yield call(
        api.user.postPush,
        {
          ...payload
        }
      )

      alert('발송되었습니다.')
      window.location.reload()
    }
  } catch (e) {
    console.error('ERR: ', e)
    alert(e)
  }
}
export function* getPush({ payload }) {

  const { page, size, select, word } = payload

  let res = null
  try {
    // let data = Object.assign(user, {})



    res = yield call(
      api.user.getPush,
      {
        query: {
          page,
          size,
          select,
          word
        }
      }
    )

    yield put(
      actions.defaultAssign({
        list: res.data
      })
    )
  } catch (e) {
    console.error('ERR: ', e)
    alert(e)
  }
}

export function* getPushDetail({ payload }) {
  const { id,size,page } = payload

  let res = null
  try {
    res = yield call(
      api.user.getPushDetail,
      {
        id,
        size,
        page
      }
    )
    console.log('@@@@ res: ', res)

    yield put(
      actions.defaultAssign({
        list: res.data
      })
    )

  } catch (e) {
    console.error('ERR: ', e)
  }
}
export function* postCareerReference({ payload }) {
  // 사가에서 같은지 안같은지 확인 후 put 태우기
  console.log('@@@@ postCareerReference enter: ', payload)
  const { type, score, content, subContent } = payload
  let res = null
  try {
    // let data = Object.assign(user, {})

    console.log('@@@@ data: ', payload)

      res = yield call(
        api.user.postCareerReference,
        {
          ...payload
        }
      )





    alert('추가되었습니다.')
    // window.location.reload()
    // history.push('/users')
  } catch (e) {
    alert('서버 오류 ', e)
    console.error('ERR: ', e)
  }
}
export default function* watch() {
  yield takeEvery(sagaTypes.GET_LIST, getListFlow);
  yield takeEvery(sagaTypes.GET_APPROVE_LIST, getApproveListFlow);
  yield takeEvery(sagaTypes.POST_APPROVE, postApprove);
  yield takeEvery(sagaTypes.POST_CAREER_REFERENCE, postCareerReference);
  yield takeEvery(sagaTypes.GET_NO_CLICK_USER, getNoClickListFlow);
  yield takeEvery(sagaTypes.GET_USER, getUserFlow)
  yield takeEvery(sagaTypes.GET_PUSH, getPush)
  yield takeEvery(sagaTypes.GET_PUSH_DETAIL, getPushDetail)
  yield takeEvery(sagaTypes.POST_PUSH, postPush)
  yield takeEvery(sagaTypes.DELETE_USER, deleteUserFlow)
  yield takeEvery(sagaTypes.PUT_USER, putUserFlow)
  yield takeEvery(sagaTypes.GET_CAREER_LIST, getCareerReferenceListFlow)

}
