import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './scss/style.scss';
import queryString from 'query-string'
import app from './reducers/app';
import firebase from "firebase";

import { getCookie } from './modules/getCookie'
import firebaseKey from './config/firebaseKey.json';

firebase.initializeApp(firebaseKey);


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
const Login = React.lazy(() => import('./views/login/Login'))

class App extends Component {

  render() {
    console.log('@@@@ app props: ', this.props)
    const { app } = this.props

    return (
      <BrowserRouter basename='/'>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            {/* guard 처리는 추후에 */}
            {/* {
              localStorage.getItem('access_token') ?
                <>
                  <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                  <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
                </>
                :
                <Redirect to="/login" />
            } */}

          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    app: state.app
  };
}


export default connect(mapStateToProps)(App);
