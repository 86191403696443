import { createAction, handleActions } from 'redux-actions'

const initialState = {
  info: {},
  job: []
}

const INIT = 'dashboard/INIT'
const DEFAULT_ASSIGN = 'app/DEFAULT_ASSIGN'

export const init = createAction(INIT)
export const defaultAssign = createAction(DEFAULT_ASSIGN)

export const sagaTypes = {
  INIT
}

export default handleActions({
  [DEFAULT_ASSIGN]: (state, action) => ({
    ...state,
    ...action.payload
  })
}, initialState)
