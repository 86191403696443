import { call, takeEvery, put } from "redux-saga/effects";
import { api } from "../services";

import * as actions from "../reducers/notice";
const { sagaTypes } = actions;

export function* postNoticeFlow({ payload }) {
  let res = null;
  try {
    console.log("@@@@ payload: ", payload);
    const { subject, context, history } = payload;
    res = yield call(api.notice.post, {
      subject,
      context,
    });
    console.log("@@@@ res: ", res);

    yield put(
      actions.getNoticeList({
        page: 0,
      })
    );

    history.push("/notice/notice");
  } catch (e) {
    console.error("ERR: ", e);
  }
}

export function* getNoticeListFlow({ payload }) {
  let res = null;
  try {
    const { page, select, word } = payload;

    res = yield call(api.notice.getList, {
      page,
      select,
      word
    });
    console.log("@@@@ res: ", res);

    yield put(
      actions.defaultAssign({
        list: res.data,
      })
    );
  } catch (error) {
    console.error("ERR: ", error);
  }
}

export function* getNoticeFlow({ payload }) {
  let res = null;
  try {
    const { id } = payload;

    res = yield call(api.notice.get, {
      id,
    });
    console.log("@@@@ res: ", res);
    yield put(
      actions.defaultAssign({
        detail: res.data,
      })
    );
  } catch (error) {
    console.error("ERR: ", error);
  }
}

export function* putNoticeFlow({ payload }) {
  let res = null;
  try {
    const { id, subject, context, history } = payload;

    res = yield call(api.notice.put, {
      id,
      subject,
      context
    });

    yield put(
      actions.getNoticeList({
        page: 0,
      })
    );

    history.push("/notice/notice");
  } catch (error) {
    console.error("ERR: ", error);
  }
}

export function* deleteNoticeFlow({ payload }) {
  let res = null;
  try {
    const { id, history } = payload;

    res = yield call(api.notice.delete, {
      id,
    });

    yield put(
      actions.getNoticeList({
        page: 0,
      })
    );

    history.push("/notice/notice");
  } catch (error) {
    console.error("ERR: ", error);
  }
}

export default function* watch() {
  yield takeEvery(sagaTypes.POST_NOTICE, postNoticeFlow);
  yield takeEvery(sagaTypes.GET_NOTICE_LIST, getNoticeListFlow);
  yield takeEvery(sagaTypes.GET_NOTICE, getNoticeFlow);
  yield takeEvery(sagaTypes.PUT_NOTICE, putNoticeFlow);
  yield takeEvery(sagaTypes.DELETE_NOTICE, deleteNoticeFlow);
}
