
import { request } from '../modules/request'
import { BASE_URL } from '../constants'

// "page": 0,
// "size": 0,
export const user = {
  getList({ query }) {
    console.log('query: ', query)
    const { page, size, select = '', word = '' } = query

    return request(
      `${BASE_URL}/users?page=${page}&size=${size}&select=${select}&word=${word}`,
      'get',
      {}
    )
  },
  getApproveList({ query }) {
    console.log('query: ', query)
    const { page, size, state = '', word = '' } = query
    console.log('getApproveList: ', query.state)
    return request(
      `${BASE_URL}/approves?page=${page}&size=${size}&state=${state}`,
      'get',
      {}
    )
  },
  get({ id }) {
    return request(
      `${BASE_URL}/users/${id}`,
      'get',
      {}
    )
  },
  getNoClick({ query }) {
    const { page, size, select = '', word = '' } = query
    return request(
      `${BASE_URL}/users/no-click?page=${page}&size=${size}&select=${select}&word=${word}`,
      'get',
      {}
    )
  },
  delete({ id }) {
    return request(
      `${BASE_URL}/users/${id}`,
      'delete',
      {}
    )
  },
  put({ id, data }) {
    return request(
      `${BASE_URL}/users/${id}`,
      'put',
      {
        ...data
      }
    )
  },
  postApprove({ type,id, block }) {
    console.log('data: ', type)
    console.log('userIdx: ', id)


    return request(
      `${BASE_URL}/block`,
      'post',
      {
        idx: id,
        type: type,
        block: block
      }
    )
  },
  postPush({ title,body,mainTab, rootView, clickPostDataType }) {

    console.log('data: ', title)
    console.log('userIdx: ', body)
    if (mainTab === "") {
      mainTab = null;
    }
    if (rootView === "") {
      rootView = null;
    }
    if (clickPostDataType === "") {
      clickPostDataType = null;
    }

    return request(
      `${BASE_URL}/push`,
      'post',
      {
        title: title,
        body: body,
        rootView: rootView,
        clickPostDataType: clickPostDataType,
        mainTab: mainTab
      }
    )

  },
  getPush({ query}) {
    const { page, size } = query

    return request(
      `${BASE_URL}/push?page=${page}&size=${size}`,
      'get',
      {
      }
    )
  },
  getPushDetail({ id,page, size }) {
    return request(
      `${BASE_URL}/push/${id}?page=${page}&size=${size}`,
      'get',
      {}
    )
  },
  postCareerReference({ type, score, content, subContent, contentType, contentSubType}) {
    console.log('type: ', type)
    console.log('content: ', content)
    console.log('score: ', score)
    console.log('subContent: ', subContent)
    console.log('contentType: ', contentType)
    console.log('contentSubType: ', contentSubType)

    return request(
      `${BASE_URL}/reference`,
      'post',
      {
        content: content,
        subContent: subContent,
        contentType: contentType,
        contentSubType: contentSubType,
        type: type,
        score: score
      }
    )
  },
  getCareerReferenceList({ page, size = 10, subType, type, value, value2 }) {
    console.log('type = ', type)
    console.log('value = ', value)
    console.log('subType = ', subType)
    console.log('value2 = ', value2)
    if(subType === undefined){
      subType = ''
    }
    return request(`${BASE_URL}/reference?page=${page}&size=${size}&type=${type}&value=${value}&subType=${subType}&value2=${value2}`, "get", {});

  }
}
