import { call, takeEvery, put, take } from 'redux-saga/effects';
import { api } from '../services'

import * as actions from '../reducers/card'
const { sagaTypes } = actions

export function* getListFlow({ payload }) {
  const { page, size } = payload
  let res = null
  try {
    res = yield call(
      api.card.getList,
      {
        query: {
          page,
          size
        }
      }
    )
    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        list: res.data
      })
    )
  } catch (e) {
    console.error('ERR :', e)
  }
}
export default function* watch() {
  yield takeEvery(sagaTypes.GET_LIST, getListFlow)
}
