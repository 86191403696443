import { createAction, handleActions } from 'redux-actions'

const initialState = {
  list: {},
  detail: {}
}

const GET_ROOM_LIST = 'clicks/GET_ROOM_LIST'

const GET_REPORT_CLICK_LIST = 'reports/GET_REPORT_CLICK_LIST'
const GET_REPORT_USER_LIST = 'reports/GET_REPORT_USER_LIST'
const GET_REPORT_REPLY_LIST = 'reports/GET_REPORT_REPLY_LIST'
const GET_REPORT_LIST = 'reports/GET_REPORT_LIST'
const GET_ROOM = 'clicks/GET_ROOM'

const DEFAULT_ASSIGN = 'clicks/DEFAULT_ASSIGN'

export const getList = createAction(GET_ROOM_LIST)

export const getReportList = createAction(GET_REPORT_LIST)
export const getReplyReportList = createAction(GET_REPORT_REPLY_LIST)
export const getClickReportList = createAction(GET_REPORT_CLICK_LIST)
export const getUserReportList = createAction(GET_REPORT_USER_LIST)
export const getRoom = createAction(GET_ROOM)
export const defaultAssign = createAction(DEFAULT_ASSIGN)

export const sagaTypes = {
  GET_REPORT_CLICK_LIST,
  GET_REPORT_REPLY_LIST,
  GET_REPORT_USER_LIST,
  GET_REPORT_LIST,
  GET_ROOM_LIST,
  GET_ROOM
}

export default handleActions({
  [DEFAULT_ASSIGN]: (state, action) => ({
    ...state,
    ...action.payload
  })
}, initialState)
