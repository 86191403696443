import { all } from "redux-saga/effects";

import users from "./users";
import payment from "./payment";
import rooms from "./clicks";
import card from "./card";
import qna from "./qna";
import dashboard from "./dashboard";
import notice from "./notice";
import contents from "./contents";

export default function* rootSaga() {
  yield all([
    users(),
    payment(),
    rooms(),
    card(),
    qna(),
    dashboard(),
    notice(),
    contents(),
  ]);
}
