import { createAction, handleActions } from 'redux-actions'

const initialState = {
  sidebarShow: 'responsive',
  isAuthorized: false
}

const DEFAULT_ASSIGN = 'app/DEFAULT_ASSIGN'

export const defaultAssign = createAction(DEFAULT_ASSIGN)

export const sagaTypes = {
  DEFAULT_ASSIGN
}

export default handleActions({
  [DEFAULT_ASSIGN]: (state, action) => ({
    ...state,
    ...action.payload
  })
}, initialState)
