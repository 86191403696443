/**
 * @param {string} url - 주소
 * @param {string} method - method
 * @param {object} data - 전달 data
 */
import axios from 'axios';
import authHeader from './authHeader';
axios.defaults.withCredentials = true;
export function request(url, method, data) {
  // console.log('fetchApi!! ' + url);
  // console.log('method: ', method);
  // console.log('data: ', data);

  //token
  console.log('axios withCredentials', axios.defaults.withCredentials)
  return axios({
    method,
    url,
    headers: authHeader(),
    data: data ? data : undefined
  });
}
