import { request } from '../modules/request'
import { BASE_URL } from '../constants'

export const qna = {
  getList({ query }) {
    const { page, size, select = '', word = '' } = query
    return request(
      `${BASE_URL}/qna?page=${page}&size=${size}&select=${select}&word=${word}`,
      'get',
      {}
    )
  },
  reply: {
    post({ id, reply }) {
      return request(
        `${BASE_URL}/qna/${id}/reply`,
        'post',
        {
          reply
        }
      )
    },
    put({ id, reply }) {
      return request(
        `${BASE_URL}/qna/${id}/reply`,
        'put',
        {
          reply
        }
      )
    }
  }
}
