import { call, takeEvery, put, take } from 'redux-saga/effects';
import { api } from '../services'

import * as actions from '../reducers/clicks'
const { sagaTypes } = actions

export function* getListFlow({ payload }) {
  const { page, size, select, word } = payload
  const { system,
    click,
    chat,
    after,
    plan,
    made,
    complete,
    reject,
    pass} = payload
  let res = null
  try {
    res = yield call(
      api.clicks.getList,
      {
        query: {
          page,
          size,
          select,
          word,
          system,
          click,
          chat,
          after,
          plan,
          made,
          complete,
          reject,
          pass
        }
      }
    )
    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        list: res.data
      })
    )
  } catch (e) {
    console.error('ERR :', e)
  }
}

export function* getRoomFlow({ payload }) {
  let res = null
  const { id } = payload
  try {
    res = yield call(
      api.clicks.get,
      {
        id
      }
    )
    console.log('@@@@1 res: ', res)

    yield put(
      actions.defaultAssign({
        detail: res.data
      })
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}
export function* getReportFlow({ payload }) {
  const { page, size, select, word } = payload
  let res = null
  try {
    res = yield call(
      api.clicks.getReportList,
      {
        query: {
          page,
          size,
          select,
          word,
        }
      }
    )
    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        detail: res.data
      })
    )
  } catch (e) {
    console.error('ERR :', e)
  }
}
export function* getReportClickFlow({ payload }) {
  const { page, size, select, word } = payload
  let res = null
  try {
    res = yield call(
      api.clicks.getReportClickList,
      {
        query: {
          page,
          size,
          select,
          word,
        }
      }
    )
    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        detail: res.data
      })
    )
  } catch (e) {
    console.error('ERR :', e)
  }
}
export function* getReportUserFlow({ payload }) {
  const { page, size, select, word } = payload
  let res = null
  try {
    res = yield call(
      api.clicks.getReportUserList,
      {
        query: {
          page,
          size,
          select,
          word
        }
      }
    )
    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        detail: res.data
      })
    )
  } catch (e) {
    console.error('ERR :', e)
  }
}
export function* getReportReplyFlow({ payload }) {
  const { page, size, select, word } = payload
  let res = null
  try {
    res = yield call(
      api.clicks.getReportReplyList,
      {
        query: {
          page,
          size,
          select,
          word,
        }
      }
    )
    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        detail: res.data
      })
    )
  } catch (e) {
    console.error('ERR :', e)
  }
}
export default function* watch() {
  yield takeEvery(sagaTypes.GET_ROOM_LIST, getListFlow)
  yield takeEvery(sagaTypes.GET_REPORT_CLICK_LIST, getReportClickFlow)
  yield takeEvery(sagaTypes.GET_REPORT_LIST, getReportFlow)
  yield takeEvery(sagaTypes.GET_REPORT_REPLY_LIST, getReportReplyFlow)
  yield takeEvery(sagaTypes.GET_REPORT_USER_LIST, getReportUserFlow)
  yield takeEvery(sagaTypes.GET_ROOM, getRoomFlow)
}
