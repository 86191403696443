import { createAction, handleActions } from "redux-actions";

const initialState = {
  list: {},
  detail: {},
};

const GET_NOTICE_LIST = "notice/GET_NOTICE_LIST";
const DEFAULT_ASSIGN = "notice/DEFAULT_ASSIGN";
const POST_NOTICE = "notice/POST_NOTICE";
const GET_NOTICE = "notice/GET_NOTICE";
const PUT_NOTICE = "notice/PUT_NOTICE";
const DELETE_NOTICE = "notice/DELETE_NOTICE";

export const postNotice = createAction(POST_NOTICE);
export const getNoticeList = createAction(GET_NOTICE_LIST);
export const defaultAssign = createAction(DEFAULT_ASSIGN);
export const getNotice = createAction(GET_NOTICE);
export const putNotice = createAction(PUT_NOTICE);
export const  deleteNotice = createAction(DELETE_NOTICE);

export const sagaTypes = {
  POST_NOTICE,
  GET_NOTICE_LIST,
  GET_NOTICE,
  PUT_NOTICE,
  DELETE_NOTICE,
};

export default handleActions(
  {
    [DEFAULT_ASSIGN]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);
