import { call, takeEvery, put } from 'redux-saga/effects';
import { api } from '../services'

import * as actions from '../reducers/payment'
const { sagaTypes } = actions

export function* getListFlow({ payload }) {
  console.log('getListFlow enter')
  const { page, size } = payload

  let res = null
  try {
    console.log("try")
    res = yield call(
      api.payment.getList,
      {
        query: {
          page,
          size
        }
      }
    )

    console.log('@@@@ res:', res)

    yield put(
      actions.getListSuccess({
        list: res.data
      })
    )

  } catch (e) {
    console.error('ERR: ', e)
  }
}

export function* getFreeFlow() {
  console.log('@@@@ enter getFreeFlow')
  let res = null
  try {
    res = yield call(api.payment.free.get)

    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        settings: res.data
      })
    )
  } catch (e) {

  }
}

export function* getFreeCodeFlow({ payload }) {
  console.log('@@@@ enter getFreeCodeFlow')
  const { page, size } = payload
  let res = null
  try {
    res = yield call(
      api.payment.free.code.get,
      {
        query: {
          page,
          size
        }
      }
    )

    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        freecode: res.data
      })
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}

export function* postFreeFlow({ payload }) {
  console.log('@@@@ enter postFreeFlow')
  const { limit, type } = payload
  let res = null
  try {
    res = yield call(
      api.payment.free.post,
      {
        limit,
        type
      }
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}

export function* postFreeCodeFlow({ payload }) {
  console.log('@@@@ enter postFreeCodeFlow')
  let res = null
  try {
    res = yield call(
      api.payment.free.code.post,
      {}
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}

export function* applyFreeFlow({ payload }) {
  const { limit, type } = payload
  console.log(payload)
  try {
    // post
    yield put(
      actions.postFree({
        limit,
        type
      })
    )
    // get
    yield put(
      actions.getFree({
        limit,
        type
      })
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}

export function* generateFreeCodeFlow() {
  let res = null
  try {
    yield put(actions.postFreeCode())
    yield put(actions.getFreeCode({
      page: 0,
      size: 10
    }))
  } catch (e) {
    console.error('ERR: ', e)
  }
}


export default function* watch() {
  yield takeEvery(sagaTypes.GET_LIST, getListFlow);
  yield takeEvery(sagaTypes.GET_FREE, getFreeFlow);
  yield takeEvery(sagaTypes.GET_FREE_CODE, getFreeCodeFlow);
  yield takeEvery(sagaTypes.POST_FREE, postFreeFlow);
  yield takeEvery(sagaTypes.POST_FREE_CODE, postFreeCodeFlow);
  yield takeEvery(sagaTypes.APPLY_FREE, applyFreeFlow);
  yield takeEvery(sagaTypes.GENERATE_FREE_CODE, generateFreeCodeFlow);
}
