import { payment } from "./payment";
import { user } from "./user";
import { clicks } from "./clicks";
import { card } from "./card";
import { qna } from "./qna";
import { dashboard } from "./dashboard";
import { notice } from "./notice";
import { contents } from "./contents";

// base-url
export const api = {
  payment,
  user,
  clicks,
  card,
  qna,
  dashboard,
  notice,
  contents,
};
