import { request } from '../modules/request'
import { BASE_URL } from '../constants'

export const card = {
  getList({ query }) {
    const { page, size } = query
    return request(
      `${BASE_URL}/cards?page=${page}&size=${size}`,
      'get',
      {}
    )
  }
}
