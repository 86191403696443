import { call, takeEvery, put } from 'redux-saga/effects';
import { api } from '../services'

import * as actions from '../reducers/dashboard'
const { sagaTypes } = actions

export function* initFlow({ payload }) {
  let res = null
  let data = {}
  try {
    res = yield call(
      api.dashboard.info.get,
      {}
    )
    console.log('@@@@ res: ', res)
    data.info = res.data

    // res = yield call(
    //   api.dashboard.job.get,
    //   {}
    // )
    console.log('@@@@ res: ', res)
    // data.job = res.data

    yield put(
      actions.defaultAssign(data)
    )
  } catch (e) {
    console.error('ERR: ', e)
  }
}

export default function* watch() {
  yield takeEvery(sagaTypes.INIT, initFlow)
}
