import app from './app'
import users from './users';
import payment from './payment'
import clicks from './clicks'
import card from './card'
import qna from './qna'
import dashboard from './dashboard'
import notice from './notice'
import contents from './contents'

import { combineReducers } from 'redux'
// import { connectRouter } from 'connected-react-router'

const rootReducer = combineReducers({
  // router: connectRouter(history),
  app,
  users,
  payment,
  clicks,
  card,
  qna,
  dashboard,
  notice,
  contents
})

export default rootReducer;
