import { createAction, handleActions } from 'redux-actions'

const initialState = {
  list: [],
}

const GET_LIST = 'card/GET_LIST'

const DEFAULT_ASSIGN = 'card/DEFAULT_ASSIGN'

export const getList = createAction(GET_LIST)
export const defaultAssign = createAction(DEFAULT_ASSIGN)

export const sagaTypes = {
  GET_LIST,
}

export default handleActions({
  [DEFAULT_ASSIGN]: (state, action) => ({
    ...state,
    ...action.payload
  })
}, initialState)
