import { call, takeEvery, put, take } from 'redux-saga/effects';
import { api } from '../services'

import * as actions from '../reducers/qna'
const { sagaTypes } = actions

export function* getListFlow({ payload }) {
  const { page, size, select, word } = payload
  let res = null
  try {
    res = yield call(
      api.qna.getList,
      {
        query: {
          page,
          size,
          select,
          word
        }
      }
    )
    console.log('@@@@ res: ', res)
    yield put(
      actions.defaultAssign({
        list: res.data
      })
    )
  } catch (e) {
    console.error('ERR :', e)
  }
}

export function* postReplyFlow({ payload }) {
  const { id, reply, history } = payload
  let res = null
  try {
    res = yield call(
      api.qna.reply.post,
      {
        id,
        reply
      }
    )
    console.log('@@@@ res: ', res)

    window.location.reload()
  } catch (e) {
    console.error('ERR :', e)
  }
}

export function* putReplyFlow({ payload }) {
  const { id, reply } = payload

  try {
    yield call(
      api.qna.reply.put,
      {
        id,
        reply
      }
    )

    window.location.reload()
  } catch (e) {
    console.error('ERR: ', e)
  }
}

export default function* watch() {
  yield takeEvery(sagaTypes.GET_LIST, getListFlow)
  yield takeEvery(sagaTypes.POST_REPLY, postReplyFlow)
  yield takeEvery(sagaTypes.PUT_REPLY, putReplyFlow)
}
