import { call, takeEvery, put } from "redux-saga/effects";
import { api } from "../services";

import * as actions from "../reducers/contents";
const { sagaTypes } = actions;

export function* getListFlow({ payload }) {
  console.log("@@@@ payload: ", payload);
  const { page = 0, size = 10 } = payload;

  let res = null;
  try {
    //
    res = yield call(api.contents.getCardResults, {
      query: {
        page,
        size,
      },
    });
    console.log("@@@@ res: ", res);

    yield put(
      actions.defaultAssign({
        list: res.data,
      })
    );
  } catch (e) {
    console.error("ERR: ", e);
  }
}

export function* getDetailFlow({ payload }) {
  let res = null;
  const { id } = payload;
  try {
    res = yield call(api.contents.getCardResult, {
      id,
    });

    yield put(
      actions.defaultAssign({
        detail: res.data,
      })
    );
  } catch (e) {
    console.error("ERR: ", e);
  }
}

export function* putDetailFlow({ payload }) {
  let res = null;
  const { id, cardSubject, cardResult, history } = payload;
  try {
    yield call(api.contents.putCardResult, {
      id,
      cardSubject,
      cardResult,
    });

    history.push('/contents/results')
  } catch (e) {
    console.error("ERR: ", e);
  }
}

export default function* watch() {
  yield takeEvery(sagaTypes.GET_LIST, getListFlow);
  yield takeEvery(sagaTypes.GET_DETAIL, getDetailFlow);
  yield takeEvery(sagaTypes.PUT_DETAIL, putDetailFlow);
}
