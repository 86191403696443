import { createAction, handleActions } from "redux-actions";

const initialState = {
  list: [],
  detail: {},
};

const GET_LIST = "contents/GET_LIST";
const GET_DETAIL = "contents/GET_DETAIL";
const PUT_DETAIL = 'contents/PUT_DETAIL';
const DEFAULT_ASSIGN = "contents/DEFAULT_ASSIGN";

export const getList = createAction(GET_LIST);
export const getDetail = createAction(GET_DETAIL);
export const putDetail = createAction(PUT_DETAIL)
export const defaultAssign = createAction(DEFAULT_ASSIGN);

export const sagaTypes = {
  GET_LIST,
  GET_DETAIL,
  PUT_DETAIL
};

/**
 * type
 * payload
 */
export default handleActions(
  {
    [DEFAULT_ASSIGN]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  initialState
);
