import { request } from "../modules/request";
import { BASE_URL } from "../constants";

export const notice = {
  getList({ page, size = 10, select, word }) {
    return request(`${BASE_URL}/notice?page=${page}&size=${size}&select=${select}&word=${word}`, "get", {});
  },
  get({ id }) {
    return request(`${BASE_URL}/notice/${id}`, "get", {});
  },
  post({ subject, context }) {
    return request(`${BASE_URL}/notice`, "post", {
      subject,
      context,
    });
  },
  put({ id, subject, context }) {
    return request(`${BASE_URL}/notice/${id}`, "put", {
      subject,
      context,
    });
  },
  delete({ id }) {
    return request(`${BASE_URL}/notice/${id}`, "delete", {});
  },
};
