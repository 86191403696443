import { createAction, handleActions } from 'redux-actions'

const initialState = {
  list: {},
}

const GET_LIST = 'qna/GET_LIST'
const POST_REPLY = 'qna/POST_REPLY'
const PUT_REPLY = 'qna/PUT_REPLY'

const DEFAULT_ASSIGN = 'qna/DEFAULT_ASSIGN'

export const getList = createAction(GET_LIST)
export const postReply = createAction(POST_REPLY)
export const putReply = createAction(PUT_REPLY)
export const defaultAssign = createAction(DEFAULT_ASSIGN)

export const sagaTypes = {
  GET_LIST,
  POST_REPLY,
  PUT_REPLY
}

export default handleActions({
  [DEFAULT_ASSIGN]: (state, action) => ({
    ...state,
    ...action.payload
  })
}, initialState)
