import { request } from '../modules/request'
import { BASE_URL } from '../constants'

// "pageNumber": 0,
// "pageSize": 0,
export const payment = {
  getList({ query }) {
    const { page, size } = query

    return request(
      `${BASE_URL}/payment?page=${page}&size=${size}`,
      'get',
      {}
    )
  },
  get({ id }) {
    return request(
      `${BASE_URL}/payment/${id}`,
      'get',
      {}
    )
  },
  free: {
    get() {
      return request(
        `${BASE_URL}/payment/free`,
        'get',
        {}
      )
    },
    post({ limit, type }) {
      return request(
        `${BASE_URL}/payment/free`,
        'post',
        {
          limit,
          type
        }
      )
    },
    code: {
      get({ query }) {
        const { page, size } = query
        return request(
          `${BASE_URL}/payment/free/code?page=${page}&size=${size}`,
          'get',
          {}
        )
      },
      post({ id }) {
        return request(
          `${BASE_URL}/payment/free/code`,
          'post',
          {}
        )
      }
    }
  }
}
